.login-form-control {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  outline: 0;
  height: 2.1rem;
  width: 100%;
  font-size: 1rem;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  transition: all .7s; }

.login-form-control:focus {
  border-color: #66afe9;
  box-shadow: none;
  outline: 0; }
