.toolbar {
    background-color: #fafafa;
}
.wrapper {

}
.editor {
    background-color: #fafafa;
    display: flex;
    width: 100%;
    height: 60vh;
}
.editorSmall {
    background-color: #fafafa;
    display: flex;
    width: 100%;
    height: 30vh;
}
.hoverable {
    float: right;

    position: relative;
    opacity: 0.5;
    color: #fafafa !important;
}
.hoverable:hover {
    opacity: 1;
    color: #fafafa !important;
}
.dropdownSelect {
    padding: 5px !important;
    margin-left: 5px !important;
    color: black !important;
}

.selectLabel {
    padding: 5px !important;
    margin-right: 1px !important;
    margin-bottom: 0px;
    color: black !important;
}